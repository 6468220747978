import styled from 'styled-components';
import { breakpoint } from 'theme';

import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  padding: ${({ theme }) => `${theme.gap.md} ${theme.gap.sm}`};
  background-color: ${({ theme }) => theme.colors.grayscaleGray0};

  ${breakpoint.md} {
    padding: ${({ theme }) => `${theme.gap.xl} ${theme.gap.sm}`};
  }
`;

export const StyledContainer = styled.div`
  margin: 0px auto;
  max-width: ${({ theme }) => theme.wrapper};
`;

export const StyledGroupListCardWrapper = styled.div`
  ${breakpoint.md} {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledHeader = styled.div`
  padding: ${({ theme }) => `${theme.gap.md} ${theme.gap.sm}`};
  background-color: ${({ theme }) => theme.colors.gray0};
`;

export const StyledIcon = styled(Icon)`
  margin: ${({ theme }) => `${theme.gap.md} 0`};
`;

export const StyledGroupListCard = styled.div`
  padding-right: ${({ theme }) => theme.gap.sm};
  margin-top: ${({ theme }) => theme.gap.md};
  max-width: 322px;
`;

export const StyledGroupListDescription = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};
`;

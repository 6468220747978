import React from 'react';
import { navigate } from 'gatsby';

import Icon, { IconType } from 'components/atoms/icon';
import Link, { LinkUrlType } from 'components/atoms/link';
import Typography from 'components/atoms/typography';

import {
  StyledCartItem,
  StyledContainer,
  StyledWrapper,
  StyledWrapperCards,
} from './more-information-section.styles';

export type MoreInformationSectionType = {
  heading: string;
  cardList: {
    text: string;
    link: LinkUrlType;
    icon: IconType;
  }[];
};

const MoreInformationSection = ({ cardList, heading }: MoreInformationSectionType) => {
  function getUri(linkUrl: LinkUrlType): string {
    if (typeof linkUrl?.internal === 'object' && linkUrl?.internal !== null) {
      return linkUrl.internal.uri;
    }
    if (typeof linkUrl?.external === 'object' && linkUrl?.external !== null) {
      return linkUrl.external.url;
    }
    return '';
  }

  return (
    <StyledWrapper>
      <StyledContainer>
        <Typography html={heading} variant="title2" component="h2" color="gray100" />
        <StyledWrapperCards>
          {cardList.map((cart) => (
            <StyledCartItem
              data-link={getUri(cart.link)}
              onClick={() => navigate(getUri(cart.link))}
            >
              <Icon name={cart.icon.name} />
              {getUri(cart.link) ? (
                <Link internal={{ uri: getUri(cart.link) }} ariaLabel={cart.text}>
                  <Typography html={cart.text} variant="title3" component="h3" color="gray100" />
                </Link>
              ) : (
                <Typography html={cart.text} variant="title3" component="h3" color="gray100" />
              )}
            </StyledCartItem>
          ))}
        </StyledWrapperCards>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default MoreInformationSection;

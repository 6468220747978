import styled from 'styled-components';
import { breakpoint } from 'theme';

import Container from 'components/atoms/container';

export const StyledContainer = styled(Container)`
  max-width: ${({ theme }) => theme.wrapper};
`;

export const StyledWrapper = styled.div`
  padding-block: 36px;

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledWrapperCards = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.gap.sm};
  margin-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.md};
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
  }
`;
export const StyledCartItem = styled.div`
  padding: ${({ theme }) => theme.gap.sm};
  box-shadow: 0 2px 8px rgba(32, 37, 50, 0.08), 0 2px 4px rgba(32, 37, 50, 0.03);
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gap.sm};
  cursor: pointer;
  ${breakpoint.md} {
    padding: ${({ theme }) => theme.gap.md};
    flex-direction: column;
    align-items: start;
    gap: ${({ theme }) => theme.gap.md};
  }
`;

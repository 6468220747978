import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import GroupSquadSection, { GroupSquadSectionType } from 'components/organisms/group-squad-section';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import MoreInformationSection, {
  MoreInformationSectionType,
} from 'components/organisms/more-information-section';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  aboutCompanyPage: {
    template: {
      aboutOrganizationData: {
        bannerPrimary: BannerPrimaryType;
        groupSquad: GroupSquadSectionType;
        moreInformationSection: MoreInformationSectionType;
      };
    };
  };
};

const AboutCompanyPage = ({
  pageContext,
  data: {
    aboutCompanyPage: {
      template: {
        aboutOrganizationData: {
          bannerPrimary: { heading, subHeading, image, text },
          groupSquad,
          moreInformationSection,
        },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      showDots
      titleTop={heading}
      subtitleTop={subHeading}
      image={image}
      description={text}
      positionTitle="left"
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <GroupSquadSection {...groupSquad} />
    <MoreInformationSection {...moreInformationSection} />
  </Layout>
);

export const query = graphql`
  query AboutCompanyPage($id: String) {
    aboutCompanyPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutCompany {
          aboutOrganizationData {
            bannerPrimary {
              heading
              subHeading
              text
              image {
                ...WpImageFragment
              }
            }
            groupSquad {
              fieldGroupName
              groupList {
                fieldGroupName
                heading
                logo
                text
              }
              heading
            }
            moreInformationSection {
              heading
              cardList {
                text
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      id
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
                icon {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutCompanyPage;

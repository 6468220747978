import React from 'react';

import { IconNameType } from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

import {
  StyledContainer,
  StyledGroupListCard,
  StyledGroupListCardWrapper,
  StyledGroupListDescription,
  StyledIcon,
  StyledWrapper,
} from './group-squad-section.styles';

export type groupListType = {
  logo: IconNameType;
} & Record<'fieldGroupName' | 'heading' | 'text', string>;

export type GroupSquadSectionType = {
  heading: string;
  groupList: groupListType[];
};

const GroupSquadSection = ({ groupList, heading }: GroupSquadSectionType) => (
  <StyledWrapper>
    <StyledContainer>
      <Typography variant="title2" html={heading} component="h2" />
      <StyledGroupListCardWrapper>
        {groupList.map((item: groupListType) => (
          <StyledGroupListCard>
            <StyledIcon name={item.logo} />
            <Typography color="gray100" variant="title2" component="h2" html={item.heading} />
            <StyledGroupListDescription color="gray100" variant="body1" html={item.text} />
          </StyledGroupListCard>
        ))}
      </StyledGroupListCardWrapper>
    </StyledContainer>
  </StyledWrapper>
);

export default GroupSquadSection;
